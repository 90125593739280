<template>
  <v-dialog v-model="dialog" :key="formKey" max-width="1024px" persistent>
    <v-card elevation="0" tile width="1024px" rounded v-bind="cardProps">
      <v-card-title v-bind="cardProps.title" class="px-6 py-4" style="font-size: 28px">
        {{ title }}
        <v-spacer />
        <slot name="actions"></slot>
      </v-card-title>

      <v-divider class="gray" />

      <v-card-text class="pa-6">
        <!-- Filtra solo los tabs visibles -->
        <v-tabs hide-slider vertical v-model="activeTab" height="100%" color="neuter" background-color="#f5f5f5" @change="onTabChange" style="border-radius: .3rem">
          <div style="max-height: 100%;">
            <span v-for="(tab, index) in filteredTabs" :key="index">
              <v-tab class="d-flex justify-start align-center">
                <v-icon left>{{ tab.icon }}</v-icon>
                {{ tab.name }}
              </v-tab>
            </span>
          </div>

          <v-divider vertical color="neuter" />

          <!-- Renderiza solo los tab-items visibles -->
          <v-tab-item eager v-for="(tab, index) in filteredTabs" :key="index" :transition="false">
            <v-card flat class="ml-12 mr-0 my-2">
              <v-card-text>
                <slot :name="tab.slotName"></slot>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <v-divider class="gray" />

    <v-card-actions class="pa-6 white" v-if="showActions">
      <slot name="bottom-actions"></slot>
      <v-spacer></v-spacer>
      <v-btn color="neuter" small text @click="closeDialog" :disabled="loading">{{ $t('general.buttons.close') }}</v-btn>
      <v-btn color="primary" v-if="showSaveAction" small elevation="0" class="t-bw-primary--text" @click="saveData" :disabled="!valid" :loading="loading">{{ $t('general.buttons.save') }}</v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      dialogProps: {
        type: Object,
        default: () => ({})
      },
      cardProps: {
        type: Object,
        default: () => ({
          height: '700px'
        })
      },
      title: {
        type: String,
        default: 'Form Details'
      },
      tabs: {
        type: Array,
        default: () => ([])
      },
      valid: {
        type: Boolean,
        default: true
      },
      showActions: {
        type: Boolean,
        default: true
      },
      showSaveAction: {
        type: Boolean,
        default: true
      },
      isEditable: {
        type: Boolean,
        default: true
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeTab: 0,
        formKey: 0
      };
    },
    computed: {
      filteredTabs() {
        return this.tabs.filter(tab => tab.visible);
      }
    },
    methods: {
      onTabChange(newTabIndex) {
        const selectedTab = this.filteredTabs[newTabIndex];
        if (selectedTab) {
          this.$emit('update:show-actions', selectedTab);
        }
      },
      clearForm() {
        this.activeTab = 0;
        this.formKey++;
      },
      closeDialog() {
        this.$emit('on:close');
      },
      saveData() {
        this.$emit('on:save');
      }
    },
    destroyed() {
      this.$root.$off('clear:form');
    }
  };
</script>

<style lang="scss" scoped>
  :deep(.v-tabs-bar) {
    border-radius: .3rem;
  }

  :deep(.v-slide-group__wrapper) {
    border-radius: 0.4em;
  }

  .v-tab--active {
    color: var(--v-t-bw-primary-base) !important;
    background-color:var(--v-primary-base) !important;
  }
</style>
